import React, { Component } from 'react';
import {createPortal} from "react-dom";
import PopupsApi from "../api/popupsApi";
import {parseCookies, setCookie} from "../base/cookieHelper";

class PopUp extends Component {

    state = {id: Math.random().toString(36).substring(7), isOpened: false};
    popupContent = React.createRef();

    open = () => {
      this.renderModal();
    };

    close = () => {
        if (!this.portalElement) { return; }
        if (this.props.onClose) { this.props.onClose(); }
        if (this.props.disablePageScroll) {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
        if (this.props.disableOuterPageScroll) {
            document.documentElement.classList.remove('is-reveal-open');
        }
        this.setState({isOpened: false}, () => {
            document.body.removeChild(this.portalElement);
            this.portalElement = null;
        });
    };

    componentWillUnmount() {
        if (this.portalElement)
            document.body.removeChild(this.portalElement);
    }

    renderCloseIcon = () => {
        if (!this.props.closeIcon) { return null; }
        let iconClass = '';
        switch(this.props.closeIcon) {
            case 'blue_fill':
                iconClass = 'reveal__close--fill  reveal__close--fill--blue';
                break;
            case 'white_fill':
                iconClass = 'reveal__close--fill';
                break;
            case 'blue_circle':
                iconClass = 'reveal__close--circle reveal__close--circle--blue';
                break;
            case 'white_circle':
                iconClass = 'reveal__close--circle';
                break;
            default:
                return '';
        }
        return <div className={`reveal__close ${iconClass}`} onClick={this.close} />;
    };

    onClick = (e) => {
        try {
            let clickedPopups = parseCookies('hr_pop_c', []);
            if (!!this.props.clickTracking && clickedPopups.indexOf(this.props.clickTracking.id) < 0 && e.target.matches('a, a *, button:not(.close-button), button:not(.close-button) *')) {
                clickedPopups.push(this.props.clickTracking.id);
                setCookie('hr_pop_c', clickedPopups);
                PopupsApi.trackClick(this.props.clickTracking.id)
            }
        } catch ({ name, message }) {
            console.log(name);
            console.log(message);
        }
    };

    renderModal = () => {
        let p = document.createElement('div');
        p.id = this.state.id;
        document.body.appendChild(p);
        this.portalElement = p;

        if (this.props.disablePageScroll) {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }

        if (this.props.disableOuterPageScroll) {
            document.documentElement.classList.add('is-reveal-open');
        }

        this.setState({isOpened: true}, () => {
            if (this.props.withCustomScripts) {
                this.portalElement.querySelectorAll('[data-flexible-popup-script]').forEach((element) => {
                    window.eval(element.innerHTML);
                });

                this.portalElement.querySelectorAll('[data-flexible-popup-script-src]').forEach((element) => {
                    let script = document.createElement("script");
                    script.src = element.src;
                    this.portalElement.appendChild(script)
                });
            }
        });
    };

    render () {
        if (!this.state.isOpened || !this.portalElement) {
            return null
        }

        let overlayClasses = ['reveal-overlay'];
        if (!!this.props.overlayClass) { overlayClasses.push(this.props.overlayClass); }

        let modalDivAttrs = {};
        if (!!this.props.clickTracking) {
            modalDivAttrs['onClick'] = this.onClick;
        }

        return createPortal(<div className={overlayClasses.join(' ')}>
            <div {...modalDivAttrs} className={`reveal ${this.props.className}`} ref={this.popupContent} >
                {this.renderCloseIcon()}
                {this.props.children}
            </div>
        </div>, this.portalElement)
    }


}

export default PopUp;