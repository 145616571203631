import React, { Component, Fragment } from 'react';
import {createPortal} from "react-dom";

export default class InjectInsideHtmlComponent extends Component {

    state = {portalElementId: null};

    componentDidMount(){
        const uniqueId = `icc${Math.random().toString(36).substring(7)}`;
        this.dynamicContentWrapper.innerHTML = this.props.html.replace(this.props.regex, `<div id="${uniqueId}"></div>`);
        this.setState({portalElementId: uniqueId});
    }

    render() {
        const PassedComponent = this.props.passedComponent;
        return (
            <Fragment>
                <div ref={el => (this.dynamicContentWrapper = el)} />
                {this.dynamicContentWrapper && this.state.portalElementId && createPortal(
                    <PassedComponent {...this.props.passedAttrs} />,
                    this.dynamicContentWrapper.querySelector(`#${this.state.portalElementId}`)
                )}
            </Fragment>
        );
    }


}